* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #333;
}

h1 {
  margin-bottom: 1rem;
}

h3 {
	font-size: 20px;
	line-height: 1.4;
	font-weight: 600;
}

p {
	margin-bottom: 4px;
}

.Content {
  font-family: sans-serif;
  width: 90vw;
  margin: 0 auto;
  padding: 2rem 0;
  list-style: none;
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 20px;
  justify-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.Searchbar {
  display: flex;
  background: #31de99;
  height: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Pagination {
  display: flex;
  margin-bottom: 50px;
  justify-content: space-evenly;
}

.Pagination button {
  border: 0;
  background: #31de99;
  border-radius: 20px;
  padding: 5px 21px;
  box-shadow: 0 3px 12px 0 #00000029;
}

input.input-searchbar {
  border-radius: 20px;
  border: 0;
  padding: 5px 20px;
  box-shadow: 0px 2px 20px 0 #61773485;
}


/* The flip card container */
.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  outline: 2px solid #31de99;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
  border-radius: 10px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 10px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  border-radius: 10px;
}


.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  display: flex;
  background: #31de99;
  color: white;
  transform: rotateY(180deg);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flip-card-back a {
	color: #626262;
	text-decoration: none;
}

.flip-card .flip-card-inner .flip-card-front img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

/* Modal container */
.modal-window {
  position: fixed;
  background-color: rgb(48 48 48 / 86%);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  text-align: center;
}

.modal-window:target {
  opacity: 1;
  pointer-events: auto;
}

.modal-window>div {
  max-width: 350px;
  position: relative;
  margin: 10% auto;
  padding: 2rem;
  background: #fff;
  color: #444;
  border-radius: 30px;
  border: 10px solid #31de99;
  box-shadow: 0px 5px 20px 0 #0000003b;
}

.modal-window img {
  width: 100%;
  margin-bottom: 20px;
}

.modal-window header {
  font-weight: bold;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: #000;
}

.footer {
	font-size: 12px;
	background: #31de99;
	display: flex;
	align-items: center;
	color: #2c2c2c;
	flex-direction: column;
	justify-content: center;
	padding: 14px 0px 7px 0px;
}